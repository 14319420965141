import React, {useEffect, useState} from 'react';
import {Button, Modal} from 'react-bootstrap';
import API from "../../api";
import CourseTable from "./CourseTable";
import DataTable from "react-data-table-component";
import successAlert from "../../services/AlertS/sucess";
import errorAlert from "../../services/AlertS/error";

const CourseRegisterModal = ({headers, modalShow, setModalShow, apptheme, handleCourselistchange,t,personinfo}) => {
    const [courses, setCourses] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await API.post('get_courses', {}, {headers});
                setCourses(res.data);
            } catch (error) {
                errorAlert(t('course.error_title'), t('course.error_message'));
            }
        };
        fetchData();
    }, []);

    const [searchText, setSearchText] = useState('');
    const handleSearch = (event) => {
        setSearchText(event.target.value);
    };

    const filteredData = courses.filter((row) =>
        row.title.toLowerCase().includes(searchText.toLowerCase()) ||
        row.code.toLowerCase().includes(searchText.toLowerCase())
    );

    const ExpandedComponent = ({data}) => (
        <div>
            <p>{t('course.title')}: <b>{data.title}</b></p>
            <p>{t('course.code')}: <b>{data.code}</b></p>
            <p>{t('course.unit')}: <b>{data.units}</b></p>
        </div>
    );

    const [selectedRows, setSelectedRows] = useState([]);

    const handleCloseModal = () => {
        setModalShow(false);
    }

    const handleRowSelected = (rows) => {
        const selectedIds = rows.selectedRows.map((row) => row.id);
        setSelectedRows(selectedIds);
    };

    const handleRegisterCourseBtn = () => {
        const req = async () => {
            try {
                const res = await API.post('student_register_course', {selectedRows}, {headers});
                handleCourselistchange();
                successAlert(t('course.success_title'), t('course.success_message'));
            } catch (e) {
                errorAlert(t('course.error_title'), t('course.error_registration_message'));
            }
        };
        req();
    };

    return (
        <Modal show={modalShow} onHide={handleCloseModal} size="lg">
            <Modal.Header>
                <Modal.Title>
                    <div className="d-flex w-100">
                        <span>{t('course.all_courses')}</span>
                        {selectedRows.length >= 1 &&
                            <button onClick={handleRegisterCourseBtn} className="btn btn-sm btn-info mx-3 text-uppercase">
                                {t('course.register_courses', {count: selectedRows.length})}
                            </button>
                        }
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <input
                    type="search"
                    className="form-control mb-3"
                    value={searchText}
                    onChange={handleSearch}
                    placeholder={t('course.search_placeholder')}
                />
                <DataTable
                    responsive
                    striped
                    onSelectedRowsChange={handleRowSelected}
                    theme={apptheme ? "dark" : undefined}
                    direction="auto"
                    subHeaderAlign="right"
                    subHeaderWrap
                    selectableRows
                    expandableRows
                    expandableRowsComponent={ExpandedComponent}
                    columns={CourseTable(personinfo,t)}
                    data={filteredData}
                    pagination
                    persistTableHead
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModal}>
                    {t('course.close')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default CourseRegisterModal;
